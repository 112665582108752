/**
 * @fileoverview gRPC-Web generated client stub for tr.com.togg.tdp.tma.ms.configurations.service.client
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as configurations_model_pb from '../configurations_model/configurations_model_pb';

export class ConfigurationsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any },
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListConfigurations = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/ListConfigurations',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    configurations_model_pb.ListConfigurationsResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    configurations_model_pb.ListConfigurationsResponse.deserializeBinary,
  );

  listConfigurations(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
  ): Promise<configurations_model_pb.ListConfigurationsResponse>;

  listConfigurations(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError, response: configurations_model_pb.ListConfigurationsResponse) => void,
  ): grpcWeb.ClientReadableStream<configurations_model_pb.ListConfigurationsResponse>;

  listConfigurations(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError, response: configurations_model_pb.ListConfigurationsResponse) => void,
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/ListConfigurations',
        request,
        metadata || {},
        this.methodDescriptorListConfigurations,
        callback,
      );
    }
    return this.client_.thenableCall(
      this.hostname_ + '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/ListConfigurations',
      request,
      metadata || {},
      this.methodDescriptorListConfigurations,
    );
  }

  methodDescriptorGetConfiguration = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/GetConfiguration',
    grpcWeb.MethodType.UNARY,
    configurations_model_pb.GetConfigurationRequest,
    configurations_model_pb.GetConfigurationResponse,
    (request: configurations_model_pb.GetConfigurationRequest) => {
      return request.serializeBinary();
    },
    configurations_model_pb.GetConfigurationResponse.deserializeBinary,
  );

  getConfiguration(
    request: configurations_model_pb.GetConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
  ): Promise<configurations_model_pb.GetConfigurationResponse>;

  getConfiguration(
    request: configurations_model_pb.GetConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError, response: configurations_model_pb.GetConfigurationResponse) => void,
  ): grpcWeb.ClientReadableStream<configurations_model_pb.GetConfigurationResponse>;

  getConfiguration(
    request: configurations_model_pb.GetConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError, response: configurations_model_pb.GetConfigurationResponse) => void,
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ + '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/GetConfiguration',
        request,
        metadata || {},
        this.methodDescriptorGetConfiguration,
        callback,
      );
    }
    return this.client_.thenableCall(
      this.hostname_ + '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/GetConfiguration',
      request,
      metadata || {},
      this.methodDescriptorGetConfiguration,
    );
  }

  methodDescriptorListenConfigurations = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/ListenConfigurations',
    grpcWeb.MethodType.SERVER_STREAMING,
    configurations_model_pb.ListenConfigurationsRequest,
    configurations_model_pb.ListenConfigurationsResponse,
    (request: configurations_model_pb.ListenConfigurationsRequest) => {
      return request.serializeBinary();
    },
    configurations_model_pb.ListenConfigurationsResponse.deserializeBinary,
  );

  listenConfigurations(
    request: configurations_model_pb.ListenConfigurationsRequest,
    metadata?: grpcWeb.Metadata,
  ): grpcWeb.ClientReadableStream<configurations_model_pb.ListenConfigurationsResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/tr.com.togg.tdp.tma.ms.configurations.service.client.ConfigurationsService/ListenConfigurations',
      request,
      metadata || {},
      this.methodDescriptorListenConfigurations,
    );
  }
}
