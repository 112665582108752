// source: configurations_model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  return Function('return this')();
}.call(null);

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var common_model_pb = require('../common_model/common_model_pb.js');
goog.object.extend(proto, common_model_pb);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.Configuration', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.Configuration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.repeatedFields_,
    null,
  );
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.displayName =
    'proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        key: jspb.Message.getFieldWithDefault(msg, 1, ''),
        value: (f = msg.getValue()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
  return proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 2:
        var value = new google_protobuf_struct_pb.Value();
        reader.readMessage(value, google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(2, f, google_protobuf_struct_pb.Value.serializeBinaryToWriter);
  }
};

/**
 * optional string key = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.protobuf.Value value = 2;
 * @return {?proto.google.protobuf.Value}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.getValue = function () {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 2)
  );
};

/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.setValue = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.clearValue = function () {
  return this.setValue(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.prototype.hasValue = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        header: (f = msg.getHeader()) && common_model_pb.RequestHeader.toObject(includeInstance, f),
        configuration:
          (f = msg.getConfiguration()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest();
  return proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_model_pb.RequestHeader();
        reader.readMessage(value, common_model_pb.RequestHeader.deserializeBinaryFromReader);
        msg.setHeader(value);
        break;
      case 2:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfiguration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(1, f, common_model_pb.RequestHeader.serializeBinaryToWriter);
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(2, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional tr.com.togg.tdp.common.model.RequestHeader header = 1;
 * @return {?proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.getHeader = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_model_pb.RequestHeader, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.RequestHeader|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.setHeader = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.clearHeader = function () {
  return this.setHeader(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Configuration configuration = 2;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.getConfiguration = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 2)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.setConfiguration = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.clearConfiguration = function () {
  return this.setConfiguration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationRequest.prototype.hasConfiguration = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        configuration:
          (f = msg.getConfiguration()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse();
  return proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfiguration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(1, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional Configuration configuration = 1;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.getConfiguration = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.setConfiguration = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.clearConfiguration = function () {
  return this.setConfiguration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.UpdateConfigurationResponse.prototype.hasConfiguration = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        header: (f = msg.getHeader()) && common_model_pb.RequestHeader.toObject(includeInstance, f),
        key: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest();
  return proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_model_pb.RequestHeader();
        reader.readMessage(value, common_model_pb.RequestHeader.deserializeBinaryFromReader);
        msg.setHeader(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(1, f, common_model_pb.RequestHeader.serializeBinaryToWriter);
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional tr.com.togg.tdp.common.model.RequestHeader header = 1;
 * @return {?proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.getHeader = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_model_pb.RequestHeader, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.RequestHeader|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.setHeader = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.clearHeader = function () {
  return this.setHeader(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string key = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.DeleteConfigurationRequest.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        header: (f = msg.getHeader()) && common_model_pb.RequestHeader.toObject(includeInstance, f),
        configuration:
          (f = msg.getConfiguration()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest();
  return proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_model_pb.RequestHeader();
        reader.readMessage(value, common_model_pb.RequestHeader.deserializeBinaryFromReader);
        msg.setHeader(value);
        break;
      case 2:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfiguration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(1, f, common_model_pb.RequestHeader.serializeBinaryToWriter);
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(2, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional tr.com.togg.tdp.common.model.RequestHeader header = 1;
 * @return {?proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.getHeader = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_model_pb.RequestHeader, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.RequestHeader|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.setHeader = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.clearHeader = function () {
  return this.setHeader(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Configuration configuration = 2;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.getConfiguration = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 2)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.setConfiguration = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.clearConfiguration = function () {
  return this.setConfiguration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationRequest.prototype.hasConfiguration = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        configuration:
          (f = msg.getConfiguration()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse();
  return proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfiguration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(1, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional Configuration configuration = 1;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.getConfiguration = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.setConfiguration = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.clearConfiguration = function () {
  return this.setConfiguration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.CreateConfigurationResponse.prototype.hasConfiguration = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        configurationsList: jspb.Message.toObjectList(
          msg.getConfigurationsList(),
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse();
  return proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.addConfigurations(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated Configuration configurations = 1;
 * @return {!Array<!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration>}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.getConfigurationsList = function () {
  return /** @type{!Array<!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 1)
  );
};

/**
 * @param {!Array<!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration>} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.setConfigurationsList = function (
  value,
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.addConfigurations = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.tr.com.togg.tdp.tma.ms.configurations.Configuration,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListConfigurationsResponse.prototype.clearConfigurationsList = function () {
  return this.setConfigurationsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        header: (f = msg.getHeader()) && common_model_pb.RequestHeader.toObject(includeInstance, f),
        key: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest();
  return proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_model_pb.RequestHeader();
        reader.readMessage(value, common_model_pb.RequestHeader.deserializeBinaryFromReader);
        msg.setHeader(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(1, f, common_model_pb.RequestHeader.serializeBinaryToWriter);
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional tr.com.togg.tdp.common.model.RequestHeader header = 1;
 * @return {?proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.getHeader = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_model_pb.RequestHeader, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.RequestHeader|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.setHeader = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.clearHeader = function () {
  return this.setHeader(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string key = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationRequest.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        configuration:
          (f = msg.getConfiguration()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse();
  return proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfiguration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(1, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional Configuration configuration = 1;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.getConfiguration = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.setConfiguration = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.clearConfiguration = function () {
  return this.setConfiguration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.GetConfigurationResponse.prototype.hasConfiguration = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        header: (f = msg.getHeader()) && common_model_pb.RequestHeader.toObject(includeInstance, f),
        keysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest();
  return proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new common_model_pb.RequestHeader();
        reader.readMessage(value, common_model_pb.RequestHeader.deserializeBinaryFromReader);
        msg.setHeader(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addKeys(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(1, f, common_model_pb.RequestHeader.serializeBinaryToWriter);
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }
};

/**
 * optional tr.com.togg.tdp.common.model.RequestHeader header = 1;
 * @return {?proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.getHeader = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.RequestHeader} */ (
    jspb.Message.getWrapperField(this, common_model_pb.RequestHeader, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.RequestHeader|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.setHeader = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.clearHeader = function () {
  return this.setHeader(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.hasHeader = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated string keys = 2;
 * @return {!Array<string>}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.getKeysList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.setKeysList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.addKeys = function (
  value,
  opt_index,
) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsRequest.prototype.clearKeysList = function () {
  return this.setKeysList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        configurations:
          (f = msg.getConfigurations()) &&
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse();
  return proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.tr.com.togg.tdp.tma.ms.configurations.Configuration();
        reader.readMessage(
          value,
          proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.deserializeBinaryFromReader,
        );
        msg.setConfigurations(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getConfigurations();
  if (f != null) {
    writer.writeMessage(1, f, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration.serializeBinaryToWriter);
  }
};

/**
 * optional Configuration configurations = 1;
 * @return {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.getConfigurations = function () {
  return /** @type{?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.tma.ms.configurations.Configuration, 1)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.tma.ms.configurations.Configuration|undefined} value
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.setConfigurations = function (
  value,
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse} returns this
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.clearConfigurations = function () {
  return this.setConfigurations(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.tma.ms.configurations.ListenConfigurationsResponse.prototype.hasConfigurations = function () {
  return jspb.Message.getField(this, 1) != null;
};

goog.object.extend(exports, proto.tr.com.togg.tdp.tma.ms.configurations);
