import React from 'react'
import { Avatar, Box, Button, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, useColorModeValue } from '@chakra-ui/react'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'
import LogoIcon from '../icons/logo.icon'
import Drawer from '../drawer/drawer.component'
import { userIsAdmin } from '../../utils/user-is-admin.util'

const Navbar: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'navbar' })
  const auth = useAuth()

  const handleLogout = async () => {
    await auth.removeUser()
    await auth.signoutRedirect()
  }

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} paddingX={4} transition="margin 0.3s ease-in-out">
      <Flex h={16} alignItems="center" justifyContent="space-between">
        {!auth.isAuthenticated || (auth.user && !userIsAdmin(auth.user.access_token)) ? (
          <LogoIcon height="2rem" />
        ) : (
          <>
            <HStack alignItems="center">
              <Drawer />
              <LogoIcon height="2rem" />
            </HStack>
            <HStack alignItems="center">
              <Flex alignItems="center">
                {auth.user && (
                  <Menu>
                    <MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
                      <Avatar size="md" src={`https://ui-avatars.com/api/?name=${encodeURIComponent(auth.user.profile.name!)}`} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            </HStack>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default Navbar
