import React from 'react'
import { Button, Drawer as ChakraDrawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, useDisclosure, VStack } from '@chakra-ui/react'
import { ChevronRightIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Drawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef(null)
  const { t } = useTranslation(undefined, { keyPrefix: 'drawer' })

  return (
    <>
      <IconButton size="md" rounded="full" icon={<HamburgerIcon />} aria-label="Open Menu" onClick={isOpen ? onClose : onOpen} ref={btnRef} />

      <ChakraDrawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={2} />
          <DrawerHeader>{t('welcome')}</DrawerHeader>

          <DrawerBody>
            <VStack spacing={2} align="stretch">
              <Link to={'/'}>
                <Button w="100%" size="sm" justifyContent="space-between">
                  {t('home')}
                  <ChevronRightIcon />
                </Button>
              </Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
    </>
  )
}

export default Drawer
