if (!process.env.REACT_APP_GRPC_URL) {
  console.error('REACT_APP_GRPC_URL is not set')
}

const grpcConfig = {
  url: process.env.REACT_APP_GRPC_URL ?? '',
  configurationsUrl: process.env.REACT_APP_GRPC_URL + '/grpc-web/configurations',
}

export default grpcConfig
