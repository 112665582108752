// source: common_model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  return Function('return this')();
}.call(null);

var common_enum_pb = require('../common_enum/common_enum_pb.js');
goog.object.extend(proto, common_enum_pb);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.CommonDate', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.GenericCodeType', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.GenericInfoType', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.GenericResponse', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.OperationResult', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.RequestHeader', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.ResponseHeader', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.model.RuleMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.RequestHeader = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.RequestHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.RequestHeader.displayName = 'proto.tr.com.togg.tdp.common.model.RequestHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.ResponseHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.ResponseHeader.displayName = 'proto.tr.com.togg.tdp.common.model.ResponseHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.RuleMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.RuleMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.RuleMessage.displayName = 'proto.tr.com.togg.tdp.common.model.RuleMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.OperationResult = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.tr.com.togg.tdp.common.model.OperationResult.repeatedFields_,
    null,
  );
};
goog.inherits(proto.tr.com.togg.tdp.common.model.OperationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.OperationResult.displayName = 'proto.tr.com.togg.tdp.common.model.OperationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.GenericResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.GenericResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.GenericResponse.displayName = 'proto.tr.com.togg.tdp.common.model.GenericResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.CommonDate = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.CommonDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.CommonDate.displayName = 'proto.tr.com.togg.tdp.common.model.CommonDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.GenericInfoType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.GenericInfoType.displayName = 'proto.tr.com.togg.tdp.common.model.GenericInfoType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tr.com.togg.tdp.common.model.GenericCodeType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tr.com.togg.tdp.common.model.GenericCodeType.displayName = 'proto.tr.com.togg.tdp.common.model.GenericCodeType';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.RequestHeader.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.RequestHeader} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.RequestHeader.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        tenantId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, ''),
        language: jspb.Message.getFieldWithDefault(msg, 3, 0),
        channel: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.RequestHeader();
  return proto.tr.com.togg.tdp.common.model.RequestHeader.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.RequestHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 3:
        var value = /** @type {!proto.tr.com.togg.tdp.common.enums.UserLanguage} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      case 4:
        var value = /** @type {!proto.tr.com.togg.tdp.common.enums.Channel} */ (reader.readEnum());
        msg.setChannel(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.RequestHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.RequestHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getChannel();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.getTenantId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.setTenantId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.setUserId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional tr.com.togg.tdp.common.enums.UserLanguage language = 3;
 * @return {!proto.tr.com.togg.tdp.common.enums.UserLanguage}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.getLanguage = function () {
  return /** @type {!proto.tr.com.togg.tdp.common.enums.UserLanguage} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.tr.com.togg.tdp.common.enums.UserLanguage} value
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional tr.com.togg.tdp.common.enums.Channel channel = 4;
 * @return {!proto.tr.com.togg.tdp.common.enums.Channel}
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.getChannel = function () {
  return /** @type {!proto.tr.com.togg.tdp.common.enums.Channel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {!proto.tr.com.togg.tdp.common.enums.Channel} value
 * @return {!proto.tr.com.togg.tdp.common.model.RequestHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.RequestHeader.prototype.setChannel = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.ResponseHeader.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.ResponseHeader} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.ResponseHeader.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
        desc: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.ResponseHeader}
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.ResponseHeader();
  return proto.tr.com.togg.tdp.common.model.ResponseHeader.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.ResponseHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.ResponseHeader}
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDesc(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.ResponseHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.ResponseHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string code = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.ResponseHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.setCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string desc = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.getDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.ResponseHeader} returns this
 */
proto.tr.com.togg.tdp.common.model.ResponseHeader.prototype.setDesc = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.RuleMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.RuleMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.RuleMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        errorCode: jspb.Message.getFieldWithDefault(msg, 1, ''),
        message: jspb.Message.getFieldWithDefault(msg, 2, ''),
        messageLevel: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.RuleMessage();
  return proto.tr.com.togg.tdp.common.model.RuleMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.RuleMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setErrorCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessageLevel(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.RuleMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.RuleMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMessageLevel();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string error_code = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.getErrorCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage} returns this
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.setErrorCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string message = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage} returns this
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string message_level = 3;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.getMessageLevel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage} returns this
 */
proto.tr.com.togg.tdp.common.model.RuleMessage.prototype.setMessageLevel = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tr.com.togg.tdp.common.model.OperationResult.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.OperationResult.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.OperationResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.OperationResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.OperationResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        resultCode: jspb.Message.getFieldWithDefault(msg, 1, ''),
        operationResultCode: jspb.Message.getFieldWithDefault(msg, 2, ''),
        description: jspb.Message.getFieldWithDefault(msg, 3, ''),
        reMessageListList: jspb.Message.toObjectList(
          msg.getReMessageListList(),
          proto.tr.com.togg.tdp.common.model.RuleMessage.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.OperationResult();
  return proto.tr.com.togg.tdp.common.model.OperationResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.OperationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setResultCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setOperationResultCode(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 4:
        var value = new proto.tr.com.togg.tdp.common.model.RuleMessage();
        reader.readMessage(value, proto.tr.com.togg.tdp.common.model.RuleMessage.deserializeBinaryFromReader);
        msg.addReMessageList(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.OperationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.OperationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.OperationResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getResultCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getOperationResultCode();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getReMessageListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.tr.com.togg.tdp.common.model.RuleMessage.serializeBinaryToWriter);
  }
};

/**
 * optional string result_code = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.getResultCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult} returns this
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.setResultCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string operation_result_code = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.getOperationResultCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult} returns this
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.setOperationResultCode = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string description = 3;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult} returns this
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * repeated RuleMessage re_message_list = 4;
 * @return {!Array<!proto.tr.com.togg.tdp.common.model.RuleMessage>}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.getReMessageListList = function () {
  return /** @type{!Array<!proto.tr.com.togg.tdp.common.model.RuleMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tr.com.togg.tdp.common.model.RuleMessage, 4)
  );
};

/**
 * @param {!Array<!proto.tr.com.togg.tdp.common.model.RuleMessage>} value
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult} returns this
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.setReMessageListList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.tr.com.togg.tdp.common.model.RuleMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tr.com.togg.tdp.common.model.RuleMessage}
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.addReMessageList = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    4,
    opt_value,
    proto.tr.com.togg.tdp.common.model.RuleMessage,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tr.com.togg.tdp.common.model.OperationResult} returns this
 */
proto.tr.com.togg.tdp.common.model.OperationResult.prototype.clearReMessageListList = function () {
  return this.setReMessageListList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.GenericResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.GenericResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.GenericResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        data: jspb.Message.getFieldWithDefault(msg, 1, ''),
        operationResult:
          (f = msg.getOperationResult()) &&
          proto.tr.com.togg.tdp.common.model.OperationResult.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericResponse}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.GenericResponse();
  return proto.tr.com.togg.tdp.common.model.GenericResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericResponse}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setData(value);
        break;
      case 2:
        var value = new proto.tr.com.togg.tdp.common.model.OperationResult();
        reader.readMessage(value, proto.tr.com.togg.tdp.common.model.OperationResult.deserializeBinaryFromReader);
        msg.setOperationResult(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.GenericResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getOperationResult();
  if (f != null) {
    writer.writeMessage(2, f, proto.tr.com.togg.tdp.common.model.OperationResult.serializeBinaryToWriter);
  }
};

/**
 * optional string data = 1;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.getData = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericResponse} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.setData = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional OperationResult operation_result = 2;
 * @return {?proto.tr.com.togg.tdp.common.model.OperationResult}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.getOperationResult = function () {
  return /** @type{?proto.tr.com.togg.tdp.common.model.OperationResult} */ (
    jspb.Message.getWrapperField(this, proto.tr.com.togg.tdp.common.model.OperationResult, 2)
  );
};

/**
 * @param {?proto.tr.com.togg.tdp.common.model.OperationResult|undefined} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericResponse} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.setOperationResult = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericResponse} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.clearOperationResult = function () {
  return this.setOperationResult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tr.com.togg.tdp.common.model.GenericResponse.prototype.hasOperationResult = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.CommonDate.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.CommonDate.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.CommonDate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.CommonDate.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        year: jspb.Message.getFieldWithDefault(msg, 1, 0),
        month: jspb.Message.getFieldWithDefault(msg, 2, 0),
        day: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.CommonDate}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.CommonDate();
  return proto.tr.com.togg.tdp.common.model.CommonDate.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.CommonDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.CommonDate}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setYear(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setDay(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.CommonDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.CommonDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.CommonDate.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
};

/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.getYear = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.tr.com.togg.tdp.common.model.CommonDate} returns this
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.setYear = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.getMonth = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.tr.com.togg.tdp.common.model.CommonDate} returns this
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.setMonth = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.getDay = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.tr.com.togg.tdp.common.model.CommonDate} returns this
 */
proto.tr.com.togg.tdp.common.model.CommonDate.prototype.setDay = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.GenericInfoType.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.GenericInfoType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.GenericInfoType.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        shortCode: jspb.Message.getFieldWithDefault(msg, 3, ''),
        value: jspb.Message.getFieldWithDefault(msg, 4, ''),
        description: jspb.Message.getFieldWithDefault(msg, 5, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.GenericInfoType();
  return proto.tr.com.togg.tdp.common.model.GenericInfoType.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericInfoType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setShortCode(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setValue(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.GenericInfoType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericInfoType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getShortCode();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string short_code = 3;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.getShortCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.setShortCode = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string value = 4;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.getValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.setValue = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string description = 5;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericInfoType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericInfoType.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.toObject = function (opt_includeInstance) {
    return proto.tr.com.togg.tdp.common.model.GenericCodeType.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.tr.com.togg.tdp.common.model.GenericCodeType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.tr.com.togg.tdp.common.model.GenericCodeType.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        code: jspb.Message.getFieldWithDefault(msg, 2, ''),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericCodeType}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tr.com.togg.tdp.common.model.GenericCodeType();
  return proto.tr.com.togg.tdp.common.model.GenericCodeType.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericCodeType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tr.com.togg.tdp.common.model.GenericCodeType}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.tr.com.togg.tdp.common.model.GenericCodeType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tr.com.togg.tdp.common.model.GenericCodeType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericCodeType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string code = 2;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericCodeType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.setCode = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.tr.com.togg.tdp.common.model.GenericCodeType} returns this
 */
proto.tr.com.togg.tdp.common.model.GenericCodeType.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

goog.object.extend(exports, proto.tr.com.togg.tdp.common.model);
