import { AuthProviderProps } from 'react-oidc-context'

if (!process.env.REACT_APP_AUTH_ISSUER) {
  console.error('REACT_APP_AUTH_ISSUER is not set')
}

if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
  console.error('REACT_APP_AUTH_CLIENT_ID is not set')
}

if (!process.env.REACT_APP_AUTH_URL) {
  console.error('REACT_APP_AUTH_URL is not set')
}

if (!process.env.REACT_APP_AUTH_CLIENT_SECRET) {
  console.error('REACT_APP_AUTH_CLIENT_SECRET is not set')
}

const authConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTH_ISSUER ?? '',
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  redirect_uri: process.env.REACT_APP_AUTH_URL ?? '',
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET ?? '',
  post_logout_redirect_uri: process.env.REACT_APP_AUTH_URL ?? '',
  automaticSilentRenew: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

export default authConfig
