import { Value } from 'google-protobuf/google/protobuf/struct_pb'
import React from 'react'
import { Input, Select, Textarea } from '@chakra-ui/react'
import { ConfigurationInputProps } from './configuration_input.component.types'
import { useTranslation } from 'react-i18next'

const ConfigurationInput = ({ type, value, onChange, isInvalid }: ConfigurationInputProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'configurations' })

  switch (type) {
    case Value.KindCase.BOOL_VALUE:
      return (
        <Select defaultValue={value.getBoolValue() ? 'true' : 'false'} isInvalid={isInvalid} onChange={e => onChange(e.target.value === 'true')}>
          <option value="true">{t('true')}</option>
          <option value="false">{t('false')}</option>
        </Select>
      )
    case Value.KindCase.NUMBER_VALUE:
      return <Input type="number" isInvalid={isInvalid} defaultValue={value.getNumberValue()} onChange={e => onChange(e.target.value)} />
    case Value.KindCase.STRING_VALUE:
      return <Input type="text" isInvalid={isInvalid} defaultValue={value.getStringValue()} onChange={e => onChange(e.target.value)} />
    case Value.KindCase.STRUCT_VALUE:
      return (
        <Textarea
          isInvalid={isInvalid}
          defaultValue={JSON.stringify(value.toJavaScript(), null, 2)}
          onChange={e => onChange(e.target.value)}
          _invalid={{ outlineColor: 'red.500' }}
        />
      )
    default:
      return null
  }
}

export default ConfigurationInput
