import React from 'react'
import { createRoot } from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthContext, AuthProvider } from 'react-oidc-context'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import themeConfig from './configs/theme.config'
import LoginPage from './routes/login/login.page'
import authConfig from './configs/auth.config'
import { userIsAdmin } from './utils/user-is-admin.util'
import ConfigurationsPage from './routes/configurations/configurations.page'

import 'moment/locale/tr'
import './styles/main.scss'
import './assets/fonts/fedra-sans/stylesheet.scss'
import './i18n'

const adminRouter = createBrowserRouter([
  {
    path: '/',
    element: <ConfigurationsPage />,
  },
])

const userRouter = createBrowserRouter([
  {
    path: '*',
    element: <LoginPage />,
  },
])
const AppWrapper = () => {
  return (
    <AuthProvider {...authConfig}>
      <AuthContext.Consumer>
        {auth => (
          <ChakraProvider theme={themeConfig} toastOptions={{ defaultOptions: { duration: 3000, position: 'top', isClosable: true } }}>
            {!auth?.isLoading && <RouterProvider router={auth?.isAuthenticated && userIsAdmin(auth?.user?.access_token) ? adminRouter : userRouter} />}
          </ChakraProvider>
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  )
}

const rootElement = document.getElementById('root')
if (rootElement) {
  createRoot(rootElement).render(<AppWrapper />)
} else {
  console.error('Root element not found')
}

reportWebVitals()
