import { Button, FormControl, FormHelperText, FormLabel, Input, Select, Textarea } from '@chakra-ui/react'
import { ConfigurationsTypes } from '../../declerations/configurations.declerations'
import { AddIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NewConfigurationProps } from './new_configuration.component.types'
import checkValidJSON from '../../utils/check-valid-json'

const NewConfiguration = ({ isLoading, onSubmit }: NewConfigurationProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'configurations' })
  const [newConfigurationKey, setNewConfigurationKey] = useState<string | undefined>(undefined)
  const [newConfigurationValue, setNewConfigurationValue] = useState<string | number | boolean | undefined>(undefined)
  const [newConfigurationType, setNewConfigurationType] = useState<ConfigurationsTypes>(ConfigurationsTypes.UNKNOWN)

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewConfigurationType(e.target.value as ConfigurationsTypes)

    switch (e.target.value) {
      case ConfigurationsTypes.STRING:
        setNewConfigurationValue('')
        break
      case ConfigurationsTypes.NUMBER:
        setNewConfigurationValue(0)
        break
      case ConfigurationsTypes.BOOLEAN:
        setNewConfigurationValue(false)
        break
      case ConfigurationsTypes.STRUCT:
        setNewConfigurationValue('')
        break
      default:
        setNewConfigurationValue(undefined)
        setNewConfigurationKey(undefined)
        break
    }
  }

  const handleAdd = () => {
    onSubmit?.(newConfigurationType, newConfigurationKey, newConfigurationValue)
  }

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="type">{t('type')}</FormLabel>
        <Select id="type" onChange={handleTypeChange} value={newConfigurationType}>
          <option value={ConfigurationsTypes.UNKNOWN}>{t('selectOption')}</option>
          <option value={ConfigurationsTypes.STRING}>String</option>
          <option value={ConfigurationsTypes.NUMBER}>Number</option>
          <option value={ConfigurationsTypes.BOOLEAN}>Boolean</option>
          <option value={ConfigurationsTypes.STRUCT}>Struct</option>
        </Select>
      </FormControl>

      {newConfigurationType !== ConfigurationsTypes.UNKNOWN && (
        <FormControl>
          <FormLabel htmlFor="key">{t('key')}</FormLabel>
          <Input onChange={e => setNewConfigurationKey(e.target.value)} />
          {newConfigurationType === ConfigurationsTypes.BOOLEAN && (
            <>
              <FormLabel htmlFor="value">{t('value')}</FormLabel>
              <Select onChange={e => setNewConfigurationValue(e.target.value === 'true')}>
                <option value="false">{t('false')}</option>
                <option value="true">{t('true')}</option>
              </Select>
              <FormHelperText>{t('booleanHelper')}</FormHelperText>
            </>
          )}
          {newConfigurationType === ConfigurationsTypes.NUMBER && (
            <>
              <FormLabel htmlFor="value">{t('value')}</FormLabel>
              <Input onChange={e => setNewConfigurationValue(parseFloat(e.target.value))} />
              <FormHelperText>{t('numberHelper')}</FormHelperText>
            </>
          )}
          {newConfigurationType === ConfigurationsTypes.STRING && (
            <>
              <FormLabel htmlFor="value">{t('value')}</FormLabel>
              <Input onChange={e => setNewConfigurationValue(e.target.value)} />
              <FormHelperText>{t('stringHelper')}</FormHelperText>
            </>
          )}
          {newConfigurationType === ConfigurationsTypes.STRUCT && (
            <>
              <FormLabel htmlFor="value">{t('value')}</FormLabel>
              <Textarea
                onChange={e => setNewConfigurationValue(e.target.value)}
                isInvalid={typeof newConfigurationValue === 'string' && newConfigurationValue.length > 0 && !checkValidJSON(newConfigurationValue)}
                _invalid={{ outlineColor: 'red.500' }}
              />
              <FormHelperText>{t('structHelper')}</FormHelperText>
            </>
          )}
        </FormControl>
      )}

      <Button
        mt={4}
        onClick={handleAdd}
        isLoading={isLoading}
        colorScheme="green"
        leftIcon={<AddIcon boxSize={3} />}
        isDisabled={newConfigurationType === ConfigurationsTypes.UNKNOWN}
      >
        {t('addNew')}
      </Button>
    </>
  )
}

export default NewConfiguration
