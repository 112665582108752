import jwtDecode from 'jwt-decode'
import { Roles } from '../declerations/roles.declerations'
import { JwtPayload } from '../declerations/jwt.decleration'

export const userIsAdmin = (token?: string): boolean => {
  if (!token) return false
  const decodedToken = jwtDecode<JwtPayload>(token)
  const roles: string[] = decodedToken.realm_access.roles
  return roles.includes(Roles.ADMIN)
}
