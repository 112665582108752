import * as React from 'react'
import { SVGProps } from 'react'

const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={165} height={40} viewBox="0 0 165 40" fill="none" {...props}>
    <path fill="#00C1E6" d="M40.872 0 0 20.017 40.895 40l-20.04-19.994L40.871 0Z" />
    <path fill="#00C1E6" d="m40.872 0 40.895 19.983L40.895 40l20.018-20.006L40.873 0Z" />
    <path
      fill="#000"
      d="M140.845 19.296a6.266 6.266 0 0 1-7.377 6.333 6.265 6.265 0 0 1-5.021-4.868 6.258 6.258 0 0 1 2.623-6.48 6.274 6.274 0 0 1 3.496-1.074 6.236 6.236 0 0 1 4.389 1.76 6.222 6.222 0 0 1 1.89 4.33Zm-.068-8.194v2.185a8.854 8.854 0 0 0-6.257-2.575 8.523 8.523 0 0 0-6.02 2.491 8.512 8.512 0 0 0-2.493 6.013 8.492 8.492 0 0 0 2.493 6.013 8.518 8.518 0 0 0 6.02 2.49 8.81 8.81 0 0 0 6.268-2.517c0 1.43 0 6.455-6.153 6.455a10.001 10.001 0 0 1-5.947-1.923l-.137 2.873a12.692 12.692 0 0 0 6.084 1.545 8.81 8.81 0 0 0 6.371-2.29 8.59 8.59 0 0 0 2.291-6.237V11.102h-2.52ZM162.559 19.285a6.263 6.263 0 0 1-7.37 6.345 6.266 6.266 0 0 1-5.027-4.865 6.255 6.255 0 0 1 2.621-6.483 6.27 6.27 0 0 1 3.497-1.075 6.225 6.225 0 0 1 4.388 1.752 6.208 6.208 0 0 1 1.891 4.326Zm-.069-8.195v2.186a8.83 8.83 0 0 0-6.256-2.564 8.523 8.523 0 0 0-6.221 2.376 8.508 8.508 0 0 0-2.605 6.122 8.505 8.505 0 0 0 8.826 8.498 8.813 8.813 0 0 0 6.245-2.575c0 1.43 0 6.455-6.153 6.455a9.839 9.839 0 0 1-5.947-1.923l-.138 2.884a12.859 12.859 0 0 0 6.085 1.534 8.806 8.806 0 0 0 6.371-2.289 8.585 8.585 0 0 0 2.291-6.238V11.09h-2.498ZM86.305 5.139v2.46h8.192v21.23h2.533L97.018 7.6l8.181-.011v-2.45H86.305ZM113.541 26.793a6.801 6.801 0 0 1-6.358-4.084 6.781 6.781 0 0 1 1.355-7.428 6.797 6.797 0 0 1 7.392-1.58 6.787 6.787 0 0 1 4.28 6.224 6.59 6.59 0 0 1-1.861 4.862 6.601 6.601 0 0 1-4.808 2.006Zm0-16.023a9.3 9.3 0 0 0-8.632 5.706 9.27 9.27 0 0 0 2.008 10.143 9.298 9.298 0 0 0 14.323-1.455 9.282 9.282 0 0 0 1.537-5.181 9.023 9.023 0 0 0-5.671-8.57 9.04 9.04 0 0 0-3.565-.643Z"
    />
  </svg>
)
export default LogoIcon
