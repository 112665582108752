import React from 'react'
import { Container } from '@chakra-ui/react'
import Layout from '../../components/layout/layout.component'
import ConfigurationsTable from '../../components/tables/configurations.component'

const ConfigurationsPage = () => {
  return (
    <Layout>
      <Container maxW="container.xl">
        <ConfigurationsTable />
      </Container>
    </Layout>
  )
}

export default ConfigurationsPage
