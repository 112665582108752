// source: common_enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  return Function('return this')();
}.call(null);

goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.BalanceCode', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.Channel', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.MediaType', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.OrderFlowTypes', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.OrderInteractionTypes', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.UserCountry', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.UserLanguage', null, global);
goog.exportSymbol('proto.tr.com.togg.tdp.common.enums.UserRole', null, global);
/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.UserLanguage = {
  USER_LANGUAGE_UNSPECIFIED: 0,
  TR: 1,
  EN: 2,
  DE: 3,
  OTHER_LANGUAGE: 100,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.UserCountry = {
  USER_COUNTRY_UNSPECIFIED: 0,
  TUR: 1,
  DEU: 2,
  GBR: 3,
  OTHER_COUNTRY: 100,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.UserRole = {
  USER_ROLE_UNSPECIFIED: 0,
  CSR_ROLE: 1,
  WSC_ROLE: 2,
  WFM_ROLE: 3,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.Channel = {
  CHANNEL_UNSPECIFIED: 0,
  MOBILE_APP: 1,
  CSR: 2,
  WSC: 3,
  MOBILE_APP_NAV: 4,
  INTERNAL_SERVER: 5,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.OrderInteractionTypes = {
  ORDER_INTER_UNSPECIFIED: 0,
  PREORDER: 1,
  INFLIGHT_CHANGE: 2,
  INFLIGHT_CANCEL: 3,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.OrderFlowTypes = {
  ORDER_FLOW_UNSPECIFIED: 0,
  RESERVE_SEAT: 1,
  RESERVE_SEAT_DEACTIVATION: 2,
  INFLIGHT_PREORDER_CANCEL: 3,
  INFLIGHT_PREORDER_CHANGE: 4,
  INFLIGHT_PREORDER_CAMPAIGN_CHANGE: 5,
  VEHICLE_PREORDER: 6,
  VEHICLE_PREORDER_NFT: 7,
  VEHICLE_PREORDER_NFT_RARE: 8,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.MediaType = {
  UNSPECIFIED: 0,
  PICTURE: 1,
  PICTURE_FOREGROUND: 2,
  PICTURE_BACKGROUND: 3,
  COLOR: 4,
  PICTURE_OVERVIEW: 5,
};

/**
 * @enum {number}
 */
proto.tr.com.togg.tdp.common.enums.BalanceCode = {
  UNSPECIFIED_BALANCE_CODE: 0,
  TL: 1,
};

goog.object.extend(exports, proto.tr.com.togg.tdp.common.enums);
