import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { CheckIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { Metadata, RpcError } from 'grpc-web'
import { Struct, Value } from 'google-protobuf/google/protobuf/struct_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { ConfigurationsServiceClient } from '../../generated'
import { Configuration, CreateConfigurationRequest, DeleteConfigurationRequest, UpdateConfigurationRequest } from '../../generated/configurations_model/configurations_model_pb'
import { ConfigurationsAdminServiceClient } from '../../generated/configurations_admin/Configurations_adminServiceClientPb'
import { ConfigurationsTypes } from '../../declerations/configurations.declerations'
import grpcConfig from '../../configs/grpc.config'
import NewConfiguration from '../new_configuration/new_configuration.component'
import debounce from 'lodash.debounce'
import ConfigurationInput from '../configuration_input/configuration_input.component'
import checkValidJSON from '../../utils/check-valid-json'

const configurationServiceClient = new ConfigurationsServiceClient(grpcConfig.configurationsUrl)
const configurationAdminServiceClient = new ConfigurationsAdminServiceClient(grpcConfig.configurationsUrl)

const ConfigurationsTable = () => {
  const auth = useAuth()
  const toast = useToast()
  const { t } = useTranslation(undefined, { keyPrefix: 'configurations' })

  const [data, setData] = useState<Configuration[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [isSaving, setIsSaving] = useState<string | null>(null)
  const [isDeleting, setIsDeleting] = useState<string | null>(null)
  const [isAdding, setIsAdding] = useState(false)
  const [editValues, setEditValues] = useState<{ [key: string]: string | number | boolean }>({})
  const [hasError, setHasError] = useState(false)

  const metadata = useMemo(() => {
    return { Authorization: `Bearer ${auth.user?.access_token}` } as Metadata
  }, [auth.user?.access_token])

  const loadConfigurations = async () => {
    setIsDataLoading(true)
    try {
      const response = await configurationServiceClient.listConfigurations(new Empty(), {})
      const configurationsList = response.getConfigurationsList()
      const sortedConfigurationsList = configurationsList.sort((a, b) => a.getKey().localeCompare(b.getKey()))
      setData(sortedConfigurationsList)
    } catch (error) {
      console.error(error)
      setHasError(true)
      toast({
        title: t('error'),
        description: t('errorLoadingData'),
        status: 'error',
      })
    } finally {
      setIsDataLoading(false)
    }
  }

  useEffect(
    () => {
      loadConfigurations()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleEditInputChange = useMemo(
    () =>
      debounce((key, newValue) => {
        setEditValues(prevValues => ({ ...prevValues, [key]: newValue }))
      }, 300),
    []
  )

  const handleAdd = async (type: ConfigurationsTypes, key?: string, value?: string | number | boolean) => {
    if (typeof key === 'undefined' || typeof value === 'undefined') {
      toast({
        title: t('error'),
        description: t('errorKeyAndValueRequired'),
        status: 'error',
      })
      return
    }

    if (data.find(item => item.getKey() === key)) {
      toast({
        title: t('error'),
        description: t('errorKeyAlreadyExists'),
        status: 'error',
      })
      return
    }

    setIsAdding(true)

    const configuration = new Configuration().setKey(key)

    switch (type) {
      case ConfigurationsTypes.STRING:
        configuration.setValue(new Value().setStringValue(value as string))
        break
      case ConfigurationsTypes.NUMBER:
        configuration.setValue(new Value().setNumberValue(value as number))
        break
      case ConfigurationsTypes.BOOLEAN:
        configuration.setValue(new Value().setBoolValue(value as boolean))
        break
      case ConfigurationsTypes.STRUCT:
        try {
          const jsonObject = JSON.parse(value as string)
          configuration.setValue(new Value().setStructValue(Struct.fromJavaScript(jsonObject)))
        } catch (e) {
          toast({
            title: t('error'),
            description: t('invalidJsonFormat'),
            status: 'error',
          })
          setIsAdding(false)
          return
        }
        break
    }

    const createConfiguration = new CreateConfigurationRequest().setConfiguration(configuration)
    try {
      await configurationAdminServiceClient.createConfiguration(createConfiguration, metadata)
      setData([...data, configuration].sort((a, b) => a.getKey().localeCompare(b.getKey())))
      toast({
        title: t('added'),
        description: t('addedWithKey'),
        status: 'success',
      })
    } catch (error) {
      if (error instanceof RpcError) {
        toast({
          title: t('error'),
          description: error.code === 7 ? t('youAreNotAuthorized') : t('errorSavingData'),
          status: 'error',
        })
      }
    }

    setIsAdding(false)
  }

  const handleDelete = async (item: Configuration) => {
    setIsDeleting(item.getKey())

    const deleteConfiguration = new DeleteConfigurationRequest().setKey(item.getKey())
    try {
      await configurationAdminServiceClient.deleteConfiguration(deleteConfiguration, metadata)
      const newData = data.filter(i => i.getKey() !== item.getKey())
      setData(newData)
      toast({
        title: t('deleted'),
        description: t('deletedWithKey', { key: item.getKey() }),
        status: 'success',
      })
    } catch (error) {
      if (error instanceof RpcError) {
        toast({
          title: t('error'),
          description: error.code === 7 ? t('youAreNotAuthorized') : t('errorDeletingData'),
          status: 'error',
        })
      }
    }

    setIsDeleting(null)
  }
  const handleUpdate = async (item: Configuration) => {
    const updatedValue = editValues[item.getKey()]

    setIsSaving(item.getKey())

    const configuration = new UpdateConfigurationRequest().setConfiguration(item)

    switch (item.getValue()?.getKindCase()) {
      case Value.KindCase.BOOL_VALUE:
        configuration.getConfiguration()?.setValue(new Value().setBoolValue(updatedValue as boolean))
        break
      case Value.KindCase.NUMBER_VALUE:
        configuration.getConfiguration()?.setValue(new Value().setNumberValue(parseFloat(updatedValue as string)))
        break
      case Value.KindCase.STRING_VALUE:
        configuration.getConfiguration()?.setValue(new Value().setStringValue(updatedValue as string))
        break
      case Value.KindCase.STRUCT_VALUE:
        try {
          if (updatedValue === '') {
            configuration.getConfiguration()?.setValue(new Value().setStructValue(Struct.fromJavaScript({})))
            break
          }

          configuration.getConfiguration()?.setValue(new Value().setStructValue(Struct.fromJavaScript(JSON.parse(updatedValue as string))))
        } catch (e) {
          console.log(e)
          toast({
            title: t('error'),
            description: t('invalidJsonFormat'),
            status: 'error',
          })
          setIsSaving(null)
          return
        }
        break
    }

    try {
      await configurationAdminServiceClient.updateConfiguration(configuration, metadata)
      const newData = [...data]
      const index = newData.findIndex(i => i.getKey() === item.getKey())
      newData[index] = item
      setData(newData)
      setEditValues(prevValues => {
        delete prevValues[item.getKey()]
        return prevValues
      })
      toast({
        title: t('saved'),
        description: t('savedWithKey', { key: item.getKey() }),
        status: 'success',
      })
    } catch (error) {
      if (error instanceof RpcError) {
        toast({
          title: t('error'),
          description: error.code === 7 ? t('youAreNotAuthorized') : t('errorSavingData'),
          status: 'error',
        })
      }
    }

    setIsSaving(null)
  }

  return (
    <VStack w="100%" spacing={4}>
      {!isDataLoading && !hasError && (
        <Box w="100%" shadow="md" p={4} borderRadius="md">
          <Text fontSize="sm" mb={4} color="gray.500">
            {t('newConfiguration')}
          </Text>
          <NewConfiguration onSubmit={handleAdd} isLoading={isAdding} />
        </Box>
      )}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t('key')}</Th>
            <Th>{t('value')}</Th>
            <Th textAlign="right">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isDataLoading && (
            <Tr>
              <Td textAlign="center" colSpan={4}>
                <Spinner size="xl" color="green.500" />
              </Td>
            </Tr>
          )}
          {!isDataLoading && data.length === 0 && (
            <Tr>
              <Td textAlign="center" colSpan={4}>
                <Text py={4}>{t('noDataFound')}</Text>
                <IconButton aria-label={t('refresh')} icon={<RepeatIcon />} onClick={loadConfigurations} colorScheme="green" />
              </Td>
            </Tr>
          )}
          {data.map(item => {
            const key = item.getKey()
            const value = item.getValue()!
            const kindCase = value!.getKindCase()
            return (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td width="100%">
                  <ConfigurationInput
                    type={kindCase}
                    value={value!}
                    onChange={e => handleEditInputChange(key, e)}
                    isInvalid={kindCase === Value.KindCase.STRUCT_VALUE && !!editValues[key] && !checkValidJSON(editValues[key] as string)}
                  />
                </Td>
                <Td textAlign="right">
                  <HStack spacing={2} justifyContent="flex-end">
                    <IconButton
                      aria-label="Check"
                      icon={<CheckIcon />}
                      onClick={() => handleUpdate(item)}
                      colorScheme="green"
                      isLoading={isSaving === key}
                      isDisabled={typeof editValues[key] === 'undefined'}
                    />
                    <Popover>
                      {popover => (
                        <>
                          <PopoverTrigger>
                            <IconButton aria-label="Delete" icon={<DeleteIcon />} colorScheme="red" isLoading={isDeleting === key} />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverBody>{t('areYouSureDelete')}</PopoverBody>
                            <PopoverFooter display="flex" justifyContent="flex-end">
                              <Button size="xs" mr={2} onClick={() => popover.onClose()}>
                                {t('no')}
                              </Button>
                              <Button size="xs" onClick={() => handleDelete(item)} colorScheme="red">
                                {t('yes')}
                              </Button>
                            </PopoverFooter>
                          </PopoverContent>
                        </>
                      )}
                    </Popover>
                  </HStack>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </VStack>
  )
}

export default ConfigurationsTable
