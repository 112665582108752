import { ColorHues, extendTheme, StyleConfig, type ThemeConfig } from '@chakra-ui/react'
import { GlobalStyles } from '@chakra-ui/theme-tools'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'cmp',
}

export const colors: Record<string, ColorHues> = {}

const styles: GlobalStyles = {
  global: {
    body: {
      bg: '#FFFFFF',
    },
  },
}

const fonts = {
  heading: 'Fedra, sans-serif',
  body: 'Fedra, sans-serif',
}

const components: Record<string, StyleConfig> = {
  Button: {
    baseStyle: {
      borderRadius: 'lg',
    },
  },
}

const theme = extendTheme({ config, fonts, styles, colors, components })

export default theme
