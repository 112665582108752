import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from '../navbar/navbar.component'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box pt={[4, 8]} pl={[4, 8]} pr={[4, 8]} pb={[4, 8]} transition="margin 0.3s ease-in-out">
        {children}
      </Box>
    </>
  )
}

export default Layout
