/**
 * @fileoverview gRPC-Web generated client stub for tr.com.togg.tdp.tma.ms.configurations.service.admin.client
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as configurations_model_pb from '../configurations_model/configurations_model_pb';

export class ConfigurationsAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string };
  options_: null | { [index: string]: any };

  constructor(
    hostname: string,
    credentials?: null | { [index: string]: string },
    options?: null | { [index: string]: any },
  ) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateConfiguration = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/UpdateConfiguration',
    grpcWeb.MethodType.UNARY,
    configurations_model_pb.UpdateConfigurationRequest,
    configurations_model_pb.UpdateConfigurationResponse,
    (request: configurations_model_pb.UpdateConfigurationRequest) => {
      return request.serializeBinary();
    },
    configurations_model_pb.UpdateConfigurationResponse.deserializeBinary,
  );

  updateConfiguration(
    request: configurations_model_pb.UpdateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
  ): Promise<configurations_model_pb.UpdateConfigurationResponse>;

  updateConfiguration(
    request: configurations_model_pb.UpdateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError, response: configurations_model_pb.UpdateConfigurationResponse) => void,
  ): grpcWeb.ClientReadableStream<configurations_model_pb.UpdateConfigurationResponse>;

  updateConfiguration(
    request: configurations_model_pb.UpdateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError, response: configurations_model_pb.UpdateConfigurationResponse) => void,
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/UpdateConfiguration',
        request,
        metadata || {},
        this.methodDescriptorUpdateConfiguration,
        callback,
      );
    }
    return this.client_.thenableCall(
      this.hostname_ +
        '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/UpdateConfiguration',
      request,
      metadata || {},
      this.methodDescriptorUpdateConfiguration,
    );
  }

  methodDescriptorDeleteConfiguration = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/DeleteConfiguration',
    grpcWeb.MethodType.UNARY,
    configurations_model_pb.DeleteConfigurationRequest,
    google_protobuf_empty_pb.Empty,
    (request: configurations_model_pb.DeleteConfigurationRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary,
  );

  deleteConfiguration(
    request: configurations_model_pb.DeleteConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
  ): Promise<google_protobuf_empty_pb.Empty>;

  deleteConfiguration(
    request: configurations_model_pb.DeleteConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError, response: google_protobuf_empty_pb.Empty) => void,
  ): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteConfiguration(
    request: configurations_model_pb.DeleteConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError, response: google_protobuf_empty_pb.Empty) => void,
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/DeleteConfiguration',
        request,
        metadata || {},
        this.methodDescriptorDeleteConfiguration,
        callback,
      );
    }
    return this.client_.thenableCall(
      this.hostname_ +
        '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/DeleteConfiguration',
      request,
      metadata || {},
      this.methodDescriptorDeleteConfiguration,
    );
  }

  methodDescriptorCreateConfiguration = new grpcWeb.MethodDescriptor(
    '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/CreateConfiguration',
    grpcWeb.MethodType.UNARY,
    configurations_model_pb.CreateConfigurationRequest,
    configurations_model_pb.CreateConfigurationResponse,
    (request: configurations_model_pb.CreateConfigurationRequest) => {
      return request.serializeBinary();
    },
    configurations_model_pb.CreateConfigurationResponse.deserializeBinary,
  );

  createConfiguration(
    request: configurations_model_pb.CreateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
  ): Promise<configurations_model_pb.CreateConfigurationResponse>;

  createConfiguration(
    request: configurations_model_pb.CreateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError, response: configurations_model_pb.CreateConfigurationResponse) => void,
  ): grpcWeb.ClientReadableStream<configurations_model_pb.CreateConfigurationResponse>;

  createConfiguration(
    request: configurations_model_pb.CreateConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError, response: configurations_model_pb.CreateConfigurationResponse) => void,
  ) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/CreateConfiguration',
        request,
        metadata || {},
        this.methodDescriptorCreateConfiguration,
        callback,
      );
    }
    return this.client_.thenableCall(
      this.hostname_ +
        '/tr.com.togg.tdp.tma.ms.configurations.service.admin.client.ConfigurationsAdminService/CreateConfiguration',
      request,
      metadata || {},
      this.methodDescriptorCreateConfiguration,
    );
  }
}
